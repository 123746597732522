import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import {PointLight, Raycaster, RectAreaLight, Vector3} from "three";
import {GUI} from "dat.gui";
import {RectAreaLightUniformsLib} from "three/examples/jsm/lights/RectAreaLightUniformsLib";
import {RectAreaLightHelper} from "three/examples/jsm/helpers/RectAreaLightHelper";
import Swal from 'sweetalert2'
import {DRACOLoader} from "three/examples/jsm/loaders/DRACOLoader";

class AvengersModel {

    // attributes
    sizes = {
        width: window.innerWidth,
        height: window.innerHeight
    };
    camera = null;
    scene = null;
    renderer = null;
    gui = null;
    model = null;
    isDrag = null;
    clientX = null;
    clientY = null;

    // constructor
    constructor() {
        this._Initialize();
    }

    // initialize class
    _Initialize() {

        // Canvas
        const canvas = document.querySelector('canvas.webgl')

        // GUI
        this.gui = new GUI()

        // Scene
        this.scene = new THREE.Scene()

        // Base camera
        this.camera = new THREE.PerspectiveCamera(65, this.sizes.width / this.sizes.height, 0.1, 750)
        this.camera.position.y = 400
        this.camera.position.z = 500
        this.scene.add(this.camera)

        // light - directional, rectangular - point light
        const light = new THREE.DirectionalLight( 0x404040 , 1.5); // soft white light
        light.position.set( 300, 300, 300 );
        light.target.position.set(300,500,0);
        this.scene.add( light );
        this.scene.add( light.target );
        RectAreaLightUniformsLib.init();
        const rectLight = new RectAreaLight(0x404040, 60.0, 40.0, 60.0); // rect back
        rectLight.position.set(28, 135.2, 70);
        rectLight.lookAt(0,2500,0)
        this.scene.add(rectLight);
        const lightR = new THREE.RectAreaLight(0x404040, 20.0, 40.0, 60.0); // rect upper
        lightR.position.set(28, 180, 70);
        lightR.lookAt(0,-2500,0)
        this.scene.add(lightR);
        const pointLight = new PointLight(0x404040, 2.5);
        pointLight.position.set(30, 180, 60);
        this.scene.add(pointLight);
        // fog
        // ADDING BACKGROUND AND FOG
        const fogColor = new THREE.Color(0xffffff);
        this.scene.background = fogColor; // Setting fogColor as the background color also
        this.scene.fog = new THREE.Fog(fogColor, 50, 850);

        // Controls
        const controls = new OrbitControls(this.camera, canvas)
        controls.enableDamping = true
        controls.target.set(70, 100, 0)
        controls.dampingFactor = 0.5; // friction
        controls.rotateSpeed = 0.05; // mouse sensitivity
        controls.minDistance=120;
        controls.zoomSpeed = 0.4; // mouse sensitivity
        controls.update() // Update Orbital Controls

        // Add Model
        this._LoadModel("model_avengers", { 'x': -1.5, 'y': 0, 'z': 0 }, {'x': .7, 'y': .5, 'z': .5});

        // renderer
        this.renderer = new THREE.WebGLRenderer({
            canvas: canvas,
            alpha: true,
            antialias: true
        })
        this.renderer.setSize(this.sizes.width, this.sizes.height)
        this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

        // helper - GUI
        const axesHelper = new THREE.AxesHelper( 5 );
        this.scene.add( axesHelper );



        // callback functions
        window.addEventListener('resize', () => this._OnWindowResize())
        this._Animate();
        this._AddEvent();


    }

    _OnWindowResize() {
        // Update sizes
        this.sizes.width = window.innerWidth
        this.sizes.height = window.innerHeight

        // Update camera
        this.camera.aspect = this.sizes.width / this.sizes.height
        this.camera.updateProjectionMatrix()

        // Update renderer
        this.renderer.setSize(this.sizes.width, this.sizes.height)
        this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    }

    _Animate() {
        requestAnimationFrame(() => {
            this.renderer.render(this.scene, this.camera);
            this._Animate()
        });
    }

    _LoadModel(pathToLoad, rotationModel, scaleModel = {'x': 1,'y': 1,'z': 1}) {
        const dracoLoader = new DRACOLoader()
        dracoLoader.setDecoderPath('/decoder/')
        const loader = new GLTFLoader();
        loader.setDRACOLoader(dracoLoader)
        loader.load('model/' + pathToLoad + '/city.glb', (gltf) => {
            gltf.scene.traverse(c => {
                c.castShadow = true;
            })
            this.model = gltf.scene.children[0];
            this.model.rotation.set(rotationModel.x, rotationModel.y, rotationModel.z);
            this.model.scale.set(scaleModel.x, scaleModel.y, scaleModel.z);
            this._HelperGUI(pathToLoad, this.model);
            document.getElementById( 'loader' ).style.display = 'none';
            document.getElementById( 'loaderGIF' ).style.display = 'none';
            this.scene.add(gltf.scene);
        }, (progress) => {
            if(progress.lengthComputable) {
                //console.log(progress.loaded / progress.total * 100);
            }
        })
    }

    _HelperGUI(name, model) {
        return;
        const num = 800;
        const positionModelGUI = this.gui.addFolder('POSITION MODEL ' + name.toUpperCase());
        positionModelGUI.add(model.position, 'x').min(-num).max(num).step(0.1);
        positionModelGUI.add(model.position, 'y').min(-num).max(num).step(0.1);
        positionModelGUI.add(model.position, 'z').min(-num).max(num).step(0.1);
        const rotationModelGUI = this.gui.addFolder('ROTATION MODEL ' + name.toUpperCase());
        rotationModelGUI.add(model.rotation, 'x').min(-num).max(num).step(0.1);
        rotationModelGUI.add(model.rotation, 'y').min(-num).max(num).step(0.1);
        rotationModelGUI.add(model.rotation, 'z').min(-num).max(num).step(0.1);
        const scaleModelGUI = this.gui.addFolder('SCALE MODEL ' + name.toUpperCase());
        scaleModelGUI.add(model.scale, 'x').min(-num).max(num).step(0.1);
        scaleModelGUI.add(model.scale, 'y').min(-num).max(num).step(0.1);
        scaleModelGUI.add(model.scale, 'z').min(-num).max(num).step(0.1);
    }

    onClick(event, mouse) {
        console.log('click')
        if(mouse == null) {
            mouse = {
                x: (event.clientX / window.innerWidth) * 2 - 1,
                y: -(event.clientY / window.innerHeight) * 2 + 1
              };
        }
    
        const raycaster = new Raycaster();
        raycaster.setFromCamera(mouse, this.camera);
    
        const intersects = raycaster.intersectObjects(this.scene.children, true);
        if (intersects.length > 0) {
          let clickedName = intersects[0].object.name;
          if(clickedName.startsWith('hulk')) {
            Swal.fire({
                title: 'HULK',
                text: 'Hulk, alter ego di Robert Bruce Banner, è un personaggio immaginario dei fumetti creato nel 1962 da Stan Lee e Jack Kirby e pubblicato negli Stati Uniti dalla Marvel Comics. La sua prima apparizione è in The Incredible Hulk (vol. 1) n. 1 del maggio 1962',
                imageUrl: 'https://style.corriere.it/wp-content/uploads/2019/04/markruffalo-hulk.jpg',
                imageWidth: 400,
                imageHeight: 200,
                imageAlt: 'Custom image'
            });
          } else if (clickedName.startsWith(('ironman'))) {
            Swal.fire({
                title: 'IRON MAN',
                text: 'Iron Man, il cui vero nome è Anthony Edward "Tony" Stark, è un personaggio immaginario dei fumetti creato nel 1963 da Stan Lee e Larry Lieber, disegnato da Don Heck e Jack Kirby e pubblicato dalla Marvel Comics. Nel film lo scienziato e miliardario Tony Stark costruisce un\'armatura tecnologicamente avanzata',
                imageUrl: 'https://cdn.bestmovie.it/wp-content/uploads/2021/06/50-502069_680345-title-movie-iron-man-tony-stark-robert-696x392.jpg',
                imageWidth: 400,
                imageHeight: 200,
                imageAlt: 'Custom image'
            });
          } else if (clickedName.startsWith('doctor_strange')) {
            Swal.fire({
                title: 'DOCTOR STRANGE',
                text: 'Basato sull\'omonimo personaggio dei fumetti Marvel Comics, il film è prodotto dai Marvel Studios. Dr. Stephen Strange, interpretato da Benedict Cumberbatch: Un rinomato neurochirurgo che, dopo un terribile incidente d\'auto, scopre un mondo di magia e dimensioni alternative',
                imageUrl: 'https://cdn.bestmovie.it/wp-content/uploads/2022/04/Doctor-Strange-4.jpg',
                imageWidth: 400,
                imageHeight: 200,
                imageAlt: 'Custom image'
            });
          } else if (clickedName.startsWith('thor')) {
            Swal.fire({
                title: 'THOR',
                text: 'Basato su Thor, personaggio della Marvel Comics a sua volta ispirato dall\'omonimo dio della mitologia norrena. È la personificazione del fulmine, della folgore e del tuono, e della tempesta',
                imageUrl: 'https://www.ilmattino.it/uploads/ckfile/201905/avengers_endgame_thor_chris_hemsworth_07102410.jpg',
                imageWidth: 400,
                imageHeight: 200,
                imageAlt: 'Custom image'
            });
          } else if (clickedName.startsWith('spiderman')) {
            Swal.fire({
                title: 'SPIDERMAN',
                text: 'Spider-Man, alter ego di Peter Parker, è un personaggio immaginario del Marvel Cinematic Universe interpretato da Tom Holland, basato sull\'omonimo supereroe creato da Stan Lee e Steve Ditko per i fumetti Marvel Comics. All\'inizio della storia Parker è uno studente di sedici anni che acquisisce poteri sovrumani dopo essere stato morso da un ragno radioattivo e decide di usare le sue nuove abilità per combattere il crimine sotto l\'alias di Spider-Man',
                imageUrl: 'https://www.repstatic.it/content/nazionale/img/2019/08/21/165820554-c48df8ba-4c3a-47a0-bcdc-d3dddbea48ac.jpg',
                imageWidth: 400,
                imageHeight: 200,
                imageAlt: 'Custom image'
            });
          } else if (clickedName.startsWith('captain_america')) {
            Swal.fire({
                title: 'CAPTAIN AMERICA',
                text: 'Capitan America (Captain America), il cui vero nome è Steve Rogers. il film racconta la storia di Steve Rogers, un magrolino ragazzo di Brooklyn che viene trasformato nel super soldato Captain America per aiutare i soldati in guerra',
                imageUrl: 'https://media-assets.wired.it/photos/615f10bf0f2b3dd590a02651/master/w_1600%2Cc_limit/1472206765_Chris-Evans-Captain-America-Trilogy.jpg',
                imageWidth: 400,
                imageHeight: 200,
                imageAlt: 'Custom image'
            });
          }
        }
    }

    _AddEvent() {
        window.addEventListener('click', () => this.onClick(event, null));
    }

}

let _APP = null;

window.addEventListener('DOMContentLoaded', () => {
    _APP = new AvengersModel();
});
